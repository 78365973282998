var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-info"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.src),expression:"src"}],staticClass:"avatar"}),_c('div',{staticClass:"right"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"item text-value"},[_vm._v(_vm._s(_vm.data.name || '-'))]),_c('div',{staticClass:"item"},[_c('i',{staticClass:"iconfont icon-yonghu1 theme-text-color"}),_c('span',{staticClass:"text-field"},[_vm._v("职务：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s(_vm.positionText || '-'))])]),_c('div',{staticClass:"item"},[_c('i',{staticClass:"iconfont icon-gongshi theme-text-color"}),_c('span',{staticClass:"text-field"},[_vm._v("工龄：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s(_vm.data.maritalStatus || '-'))])])]),_c('div',[_c('router-link',{staticClass:"switch-btn",staticStyle:{"line-height":"26px","height":"26px","display":"inline-block"},attrs:{"tag":"span","to":{
          name: 'hrms.resume.print',
          query: { id: _vm.id }
        }}},[_c('i',{staticClass:"iconfont icon-printer"}),_vm._v("快速打印")]),_c('router-link',{staticClass:"switch-btn",staticStyle:{"line-height":"26px","height":"26px","display":"inline-block"},attrs:{"tag":"span","to":{
          name: 'hrms.resume.preview',
          query: { id: _vm.id }
        }}},[_c('i',{staticClass:"iconfont icon-switch"}),_vm._v("扫描件模式")])],1)]),_c('table',{staticStyle:{"width":"80%"}},[_c('tr',[_c('td',[_c('span',{staticClass:"text-field"},[_vm._v("所属机构：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s(_vm.orgText || '-'))])]),_c('td',[_c('span',{staticClass:"text-field"},[_vm._v("是否在编：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s(_vm.data.type || '-'))])]),_c('td',[_c('span',{staticClass:"text-field"},[_vm._v("在岗状态：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s(_vm.data.jobTypeC || '-'))])])]),_c('tr',[_c('td',[_c('span',{staticClass:"text-field"},[_vm._v("参加工作时间：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s((_vm.data.correctionDate || '-').substr(0, 10)))])]),_c('td',[_c('span',{staticClass:"text-field"},[_vm._v("进入本单位时间：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s((_vm.data.entryDate || '-').substr(0, 10)))])]),_c('td',[_c('span',{staticClass:"text-field"},[_vm._v("人员类别：")]),_c('span',{staticClass:"text-value"},[_vm._v(_vm._s(_vm.data.jobType || '-'))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }