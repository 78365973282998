<style scoped lang="less">
  .details-menus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 5px;
  }
  .menu {
    flex: 1;
    cursor: pointer;
    text-align: center;
    span {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      white-space: nowrap;
      position: relative;
      display: inline-block;
      transition: all .3s;
    }
    &.active {
      span::after {
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        bottom: -5px;
        height: 2px;
        width: 100%;
      }
    }
  }
  .pink-theme {
    .menu {
      &.active, &:hover {
        span {
          color: #F4628F;
        }
      }
      &.active {
        span::after {
          background-color: #F4628F;
        }
      }
    }
  }
  .yellow-theme {
    .menu {
      &.active, &:hover {
        span {
          color: #6C573C;
        }
      }
      &.active {
        span::after {
          background-color: #6C573C;
        }
      }
    }
  }
  .blue-theme, .dark-theme {
    .menu {
      &.active, &:hover {
        span {
          color: #6F76B7;
        }
      }
      &.active {
        span::after {
          background-color: #6F76B7;
        }
      }
    }
  }
</style>

<template>
  <div class="details-menus">
    <router-link v-for="menu in menus" :key="menu.name" :to="{
      name: menu.name,
      query: { id: id }
    }" tag="div" class="menu" :class="{active: menu.name === active}">
      <span>{{menu.label}}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    active () {
      return this.$route.name
    },
    id () {
      return this.$route.query.id
    },
    menus () {
      return [
        { label: '基础信息', name: 'hrms.resume.details.base' },
        { label: '工作信息', name: 'hrms.resume.details.work' },
        { label: '教育经历', name: 'hrms.resume.details.edu' },
        { label: '上岗经历', name: 'hrms.resume.details.job' },
        { label: '奖励信息', name: 'hrms.resume.details.rew' },
        { label: '培训信息', name: 'hrms.resume.details.train' },
        { label: '专业技术资格证书', name: 'hrms.resume.details.certificate' },
        { label: '执业证书', name: 'hrms.resume.details.certificatePra' },
        { label: '职业资格证书', name: 'hrms.resume.details.certificateQua' },
        { label: '其他证书', name: 'hrms.resume.details.certificateOther' },
        { label: '继续教育', name: 'hrms.resume.details.eduContinue' },
        { label: '全部', name: 'hrms.resume.details.all' }
      ]
    }
  }
}
</script>
